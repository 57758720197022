import { initializeApp } from "firebase/app";
import {
  getAuth,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

//For devs
// const firebaseConfig = {
//   apiKey: "AIzaSyAtY2qdaHRl7UXUoauweiLqKsaZjw7abbY",
//   authDomain: "blue-sky-dev-7fed9.firebaseapp.com",
//   projectId: "blue-sky-dev-7fed9",
//   storageBucket: "blue-sky-dev-7fed9.appspot.com",
//   messagingSenderId: "838380185904",
//   appId: "1:838380185904:web:cbdf666ae208a8e00ac0f5",
//   measurementId: "G-NLT0J1BJ7W",
// };

//For prod
// const firebaseConfig = {
//   apiKey: "AIzaSyDQDXu3L9_zQyn7_7NBu9ZKJ_1dt3JMYc4",
//   authDomain: "blue-sky-ace6e.firebaseapp.com",
//   projectId: "blue-sky-ace6e",
//   storageBucket: "blue-sky-ace6e.appspot.com",
//   messagingSenderId: "772638500460",
//   appId: "1:772638500460:web:adf6d9f10b821c21091a49",
//   measurementId: "G-129Z544LGM",
// };

// For demo
const firebaseConfig = {
  apiKey: "AIzaSyCRonh8M_eexkZehrHiRMBgpFdQLpo2ew8",
  authDomain: "blue-sky-demo.firebaseapp.com",
  projectId: "blue-sky-demo",
  storageBucket: "blue-sky-demo.appspot.com",
  messagingSenderId: "222796357889",
  appId: "1:222796357889:web:8bad7d9e4e8109f75dfb00",
  measurementId: "G-CVQ14LJ9SP",
};

//Hawaii demo
// const firebaseConfig = {
//   apiKey: "AIzaSyCIj9f_M3UnPvsyoEtNyo8BHfBT86VdUL8",
//   authDomain: "bluesky-hawaii-demo.firebaseapp.com",
//   projectId: "bluesky-hawaii-demo",
//   storageBucket: "bluesky-hawaii-demo.appspot.com",
//   messagingSenderId: "939053247570",
//   appId: "1:939053247570:web:f0d98f9898346ce485438b",
//   measurementId: "G-FJ6NH8XTNK",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

setPersistence(auth, browserSessionPersistence)
  .then(() => {})
  .catch((error) => {
    console.error("Error setting auth persistence:", error);
  });

export { auth, db, storage };
